.home-btn a {
    color: #2b2b2b;
    text-decoration: none;
}

.home-btn a:hover {
    color: #2b2b2b;
}

.home-btn a.active {
    font-weight: bold;
}