.talk-times .date {
    display: block;
}

.talk-description {
    font-size: 18px;
    line-height: 22px;
    font-weight: lighter;
}

.talk-details .card-img-top {
    max-height: 60px;
}