.date-select a {
    color: #05ffcf;
}

.date-select a:hover {
    color: #1ad3af;
}

.date-select a.active {
    font-weight: bold;
    text-decoration: underline;
}