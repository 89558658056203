.talk-title {
    color: #2b2b2b;
    font-size: 34px;
    line-height: 36px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.talk-title a {
    color: #2b2b2b;
    text-decoration: none;
}

.talk-times {
    color: #2b2b2b;
}

.talk-speakers a {
    color: #2b2b2b;
    text-decoration: none;
}

.talk-tracks {
    margin-top: 15px;
}

.talk-tracks .btn {
    margin-right: 10px;
}

.talk-tracks .btn:hover {
    text-decoration: underline;
}

.talk-tracks .btn:last-of-type {
    margin-right: 0;
}

.talk-times .timezone {
    display: block;
}