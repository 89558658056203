/* Font definitions */
@font-face {
    font-family: "Karbon";
    font-display: auto;
    src: url(../public/fonts/Karbon-light.woff2);
    font-weight: 300;
    font-style: light;
}

@font-face {
    font-family: "Karbon";
    font-display: auto;
    src: url(../public/fonts/Karbon-regular.woff2);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Karbon";
    font-display: auto;
    src: url(../public/fonts/Karbon-medium.woff2);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Karbon";
    font-display: auto;
    src: url(../public/fonts/Karbon-semibold.woff2);
    font-weight: 600;
    font-style: bold;
}

body {
    font: 14px "Karbon", "Century Gothic", Futura, sans-serif;
    margin: 30px;
}

a:hover {
  text-decoration: underline;
}