.speaker-talks a {
    text-decoration: none;
}

.speaker-detail .card-img-top {
    border-bottom: 5px solid #05ffcf;
}

.speaker-details .speaker-talks h2 {
    margin-top: 30px;
}

.talk-times .date {
    display: block;
}

.speaker-bio,
.speaker-talks p {
    font-size: 18px;
    line-height: 22px;
    font-weight: lighter;
}